// @ts-check

class Route {
    public path: string;
    public component: () => void;
    public key: string;

    constructor(data?: any) {
        this.path = data.path || "/";
        this.component = data.component || null;
        this.key = data.key || "";
    }
}

export const getRouteArrayFromGalleries = (galleries: any[], getGalleryFunc: () => void): Route[] => {
    const galleryRouteArray: Route[] = [];

    for (const gallery of galleries) {

        const galleryRoute: Route = new Route(
            {
                component: getGalleryFunc,
                key: gallery._id,
                path: "/" + gallery.key,
            },
        );

        galleryRouteArray.push(galleryRoute);
    }

    return galleryRouteArray;
};

export const setRouteArray = (
    routesFromGalleries: Route[],
    getHome: () => void,
    getAbout: () => void,
    getContact: () => void,
): Route[] => {

    const routeArray: Route[] = [
      {path: "/", component: getHome, key: "homeRoute"},
      {path: "/about", component: getAbout, key: "aboutRoute"},
      {path: "/contact", component: getContact, key: "contactRoute"},
    ];

    for (const route of routesFromGalleries) {
      routeArray.push(route);
    }

    return routeArray;
};

// @ts-check

export const getModeOnLoadAndCurrentGallery = (): object => {
    let modeOnLoad: string;
    let currentGallery: string | null;
    let splashStatus: boolean;

    if (
      window.location.pathname !== "/"
      &&
      window.location.pathname !== "/about"
      &&
      window.location.pathname !== "/contact"
      &&
      window.location.pathname !== "/private-view"
    ) {
      modeOnLoad = "gallery";
      const splitPathName: string[] = window.location.pathname.split("/", 2);
      currentGallery = splitPathName[1];
      splashStatus = false;
    } else if (
        window.location.pathname === "/about"
        ||
        window.location.pathname === "/contact"
    ) {
      modeOnLoad = "home";
      currentGallery = null;
      splashStatus = false;
    } else {
        modeOnLoad = "home";
        currentGallery = null;
        splashStatus = true;
    }

    return {
        currentGallery,
        modeOnLoad,
        splashStatus,
    };
};

export const checkWindowPathnameForPrivate = (path: string): boolean => {
    const newPath: string[] = path.split("/");

    let retVal: boolean;
    newPath[1] === "private-view" ? retVal = true : retVal = false;
    return retVal;
};

export const detectBrowser = (): string => {
    const { detect }: any = require("detect-browser");
    const browser: any = detect();
    return browser.name;
};

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import {
  checkWindowPathnameForPrivate,
  getModeOnLoadAndCurrentGallery,
  detectBrowser
} from './utils/tsInitUtils.ts';

import { 
  Loading 
} from './components/pureComponents';

import {
  AsyncHeader,
  AsyncMainSection,
  AsyncLightHeader,
  AsyncNoMatch,
  AsyncGallery,
  AsyncAbout,
  AsyncContact,
  AsyncSplash
} from './components/AsyncComponents';

import {
  getSanityClient,
  getDataPlusUrls,
} from './utils/tsAppDidMountUtils.ts';

import {
  getRouteArrayFromGalleries,
  setRouteArray
} from './utils/routes.ts';

import {
  getGalleriesObject
} from './utils/galleries.ts';

import sunIcon from './images/sun.svg';
import moonIcon from './images/moon.svg';

import './css/App.css';
import './css/animate.min.css';

const OnImagesLoaded = require('react-on-images-loaded');

class App extends Component {

  /* Lifecycle -------------------------------------------------------------------------------------------------------------------------------------------------------------*/

  constructor(props) {
    super(props)  

    let modeOnLoadAndCurrentGallery = getModeOnLoadAndCurrentGallery();

    this.state = {
      hasError: false,
      privateViewStatus: checkWindowPathnameForPrivate(window.location.pathname),
      currentGallery: modeOnLoadAndCurrentGallery.currentGallery,
      mode: modeOnLoadAndCurrentGallery.modeOnLoad,
      splash: modeOnLoadAndCurrentGallery.splashStatus,
      nightMode: false,
      dayNightToggleHighlighted: false,
      sessionBrowser: detectBrowser(),
      dataReceivedFromSanity: false,
      header: null,
      appStyle: null,
      imagesLoaded: false,
      mainSectionImagesLoaded: false
    }

    this.getHome = this.getHome.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getGallery = this.getGallery.bind(this);
    this.getAbout = this.getAbout.bind(this);
    this.splashAction = this.splashAction.bind(this);
    this.toggleDayNightColors = this.toggleDayNightColors.bind(this);
    this.getDayNightToggle = this.getDayNightToggle.bind(this);
    this.getNoMatch = this.getNoMatch.bind(this);

    this.getLoaded = this.getLoaded.bind(this);

    this.setImagesLoadedFromMainSection = this.setImagesLoadedFromMainSection.bind(this);
  }

  render() {
    let appRenderJsx = this.getAppRenderJsx();

    return (
      <Router>
        {appRenderJsx}
      </Router>
    );
  }
  
  componentDidMount() {
    window.addEventListener('mousedown', this.handleClick);

    getSanityClient()
    .then(dataFromSanity => {
      return getDataPlusUrls(dataFromSanity, this.state.sessionBrowser);
    })
    .then(dataFromSanityPlusUrls => {
      this.setState({
        galleries: dataFromSanityPlusUrls.galleries,
        about: dataFromSanityPlusUrls.about,
        publicGalleries: dataFromSanityPlusUrls.publicGalleries,
        galleriesObject: getGalleriesObject(dataFromSanityPlusUrls.galleries),
        routes: this.getRoutes(dataFromSanityPlusUrls.galleries, getRouteArrayFromGalleries, setRouteArray),
        splashPage: dataFromSanityPlusUrls.splashPage,
        headerNavigation: dataFromSanityPlusUrls.headerNavigation,
        colorSelection: dataFromSanityPlusUrls.colorSelection,
        nightMode: dataFromSanityPlusUrls.colorSelection.nightModeOnLoad,
        errorPage: dataFromSanityPlusUrls.errorPage,
        dataReceivedFromSanity: true
      })
    })
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleClick);
  }

  /* Called by render ------------------------------------------------------------------------------------------------------------------------------------------------------*/

  getAppRenderJsx() {

    let appRenderJsx;

    if(this.state.dataReceivedFromSanity === true) {
      let renderData = this.getHeaderAppStyleAndDayNighToggle();
      appRenderJsx = this.getAppRenderJsxLoaded(renderData);

    } else if(this.state.dataReceivedFromSanity === false) {
      appRenderJsx = this.getAppRenderJsxNotLoaded();
    }

    return appRenderJsx;
  }

  /* Called by render -> getAppRenderJsx -----------------------------------------------------------------------------------------------------------------------------------*/
  
  getHeaderAppStyleAndDayNighToggle() {
    let header;
    let appStyle;
    let footer;

    if(
      this.state.dataReceivedFromSanity === true
      &&
      this.state.imagesLoaded === true
    ) {
        let dayNightToggle;
        let credit;
        let lightHeader;

        this.state.splash === true && this.state.mode === "home" ? lightHeader = true : lightHeader = false;
        lightHeader === true ? header = this.getLightHeader() : header = this.getHeader();
        this.state.nightMode ? appStyle = this.state.colorSelection.nightModeColours.bGColorNight : appStyle = this.state.colorSelection.dayModeColours.bGColorDay;

        if(this.state.splash === true) {
          dayNightToggle = null;
          credit = null;
        } else if(this.state.splash === false) {
          let dayNightToggleImgSrc = this.getDayNightToggleImgSrc();
          dayNightToggle = this.getDayNightToggle(dayNightToggleImgSrc);
          credit = this.getCredit();
          footer = this.getFooter(dayNightToggle, credit);
        }
    } else {
      header = null;
      appStyle = null;
      footer = null;
    } 

    return {
      header: header,
      appStyle: appStyle,
      footer: footer
    }
  }

  getAppRenderJsxLoaded(renderData) {
    return (
      <div className="App" style={renderData.appStyle}>
        <OnImagesLoaded
          onLoaded={this.getLoaded}
        >
          {renderData.header}
          {this.state.routes}
          {renderData.footer}
        </OnImagesLoaded>
      </div>
    );
  }

  getAppRenderJsxNotLoaded() {
    return (
      <div className="App">
      </div>
    );
  }

  /* Called by render -> getAppRenderJsx -> getHeaderAppStyleAndDayNighToggle ----------------------------------------------------------------------------------------------*/

  getDayNightToggleImgSrc() {

    let src;

    if(this.state.nightMode === true) {
      src = moonIcon;
    } else if(this.state.nightMode === false) {
      src = sunIcon;
    }
    
    return src;
  }

  getDayNightToggle(dayNightToggleImgSrc) {

    let toggleStyle;

    if(this.state.dayNightToggleHighlighted === true) {
      toggleStyle = {opacity: "1"};
    } else if(this.state.dayNightToggleHighlighted === false) {
      toggleStyle = null;
    }

    return (
      <div className="dayNightToggleContainer">
        <img src={dayNightToggleImgSrc} alt="dayNightToggle" className="dayNightToggleImage" onClick={this.toggleDayNightColors} style={toggleStyle} title="Click to toggle day / night mode"/>
      </div>
    )
  }

  getFooter(dayNightToggle, credit) {
    return (
      <div className="footer">
        <div className="footerColLeft">
          {dayNightToggle}
        </div>
        <div className="footerColCenterRight">
          {credit}
        </div>
      </div>
    );
  }

  getCredit() {
    let oldCreditStyle;
    
    if(this.state.nightMode === true) {
      oldCreditStyle = this.state.colorSelection.nightModeColours.textColorNight;
    } else if(this.state.nightMode === false) {
      oldCreditStyle = this.state.colorSelection.dayModeColours.textColorDay;
    }

    let creditStyle = {
      color: oldCreditStyle.color
    };

    if(this.state.mainSectionImagesLoaded === true) {
      creditStyle['opacity'] = 1;
    } else if(this.state.mainSectionImagesLoaded === false) {
      creditStyle['opacity'] = 0;
    }

    return (
      <div>
        <div className="creditContainerCenter">
          <p style={creditStyle}>
            © Bethany Borrough
          </p>
        </div>
        <div className="creditContainerRight">
          <p style={creditStyle}>
            Site designed by <a href="mailto: ahalkerston.webdesign@gmail.com" className="creditEmailLink" style={creditStyle}><strong>Andrew Halkerston</strong></a>
          </p>
        </div>
      </div>
    );
  }

  /* Called by render -> getAppRenderJsx -> getAppRenderJsxLoaded ----------------------------------------------------------------------------------------------------------*/

  getLoaded() {
    if(
      this.state.imagesLoaded === false
    ) {
      this.setState({
        imagesLoaded: true
      })
    }
  }

  /* Called by componentDidMount -------------------------------------------------------------------------------------------------------------------------------------------*/

  getRoutes(galleries) {
    let routesFromGalleries = getRouteArrayFromGalleries(galleries, this.getGallery);
    let routeArray = setRouteArray(
      routesFromGalleries,
      this.getHome,
      this.getAbout,
      this.getContact
    );

    let containerStyle = {
      minHeight: "71vh"
    }

    return (
      <div className="mainPageGalleryContainer" style={containerStyle}>
        <Switch>
            {routeArray.map((e) => {
                return (
                  <Route exact path={e.path} component={e.component} key={e.key}/>
                )
            })}
            <Redirect from='/private-view' to='/'/>
            <Route component={this.getNoMatch}/>
        </Switch>
      </div>
    )
  }

  getHome() {

    let galleries;

    if(this.state.privateViewStatus === true) {
      galleries = this.state.galleries;
    } else {
      galleries = this.state.publicGalleries
    } 
  
    let content;

    if(
      this.state.galleries
      &&
      this.state.publicGalleries
      &&
      this.state.splashPage
    ) {
      this.state.splash === false ? content = this.getMainSectionJSX(galleries) : content = this.getSplashImageAndLogo();
    } else {
      content = Loading;
    }

    return content;
  }

  toggleDayNightColors() {
    this.state.nightMode ? this.setState({nightMode: false}) : this.setState({nightMode: true})
  }

  /* Event handling --------------------------------------------------------------------------------------------------------------------------------------------------------*/

  handleClick(e) {
    if(
      e.target.className === "mainPageGalleryImage"
      ||
      e.target.className === "mainPageGalleryItemHeading"
    ) {
      this.setState({
        currentGallery: e.target.id,
        mode: "gallery"
      })
    }
  }

  /* Async component calls -------------------------------------------------------------------------------------------------------------------------------------------------*/

  getAbout() {
    return (
      <AsyncAbout 
        data={this.state.about}
      />
    );
  }

  getContact() {
    return(
      <AsyncContact />
    );
  } 

  getNoMatch() {
    return (
      <AsyncNoMatch
        nightMode={this.state.nightMode}
        textColorNight={this.state.colorSelection.nightModeColours.textColorNight.color}
        textColorDay={this.state.colorSelection.dayModeColours.textColorDay.color}
        imageUrl={this.state.errorPage.image.url}
        imageUrlTiny={this.state.errorPage.image.tinyUrl}
        imageAltText={this.state.errorPage.image.imageAltText}
        errorTextLine1={this.state.errorPage.errorTextLine1}
        errorTextLine2={this.state.errorPage.errorTextLine2}
      />
    );
  }

  getGallery() {
    return(
      <AsyncGallery 
        data={this.state.galleriesObject[this.state.currentGallery]}
      />
    );
  }

  getHeader() {
    return (
      <AsyncHeader 
        headerFromSanity={this.state.headerNavigation}
        splash={this.state.splash}
        nightMode={this.state.nightMode}
        nightModeTextColor={this.state.colorSelection.nightModeColours.textColorNight}
        dayModeTextColor={this.state.colorSelection.dayModeColours.textColorDay}
      />
    );
  }

  getLightHeader() {
    return (
      <AsyncLightHeader 
        headerFromSanity={this.state.headerNavigation}
      />
    );
  }

  getSplashImageAndLogo() {
    return (
      <AsyncSplash 
        url={this.state.splashPage.image.url}
        tinyUrl={this.state.splashPage.image.tinyUrl}
        imageAltText={this.state.splashPage.image.imageAltText}
        splashAction={this.splashAction}
        welcomeTitle={this.state.splashPage.welcomeTitle}
        appImagesLoaded={this.state.imagesLoaded}
      />
    );
  }

  getMainSectionJSX(galleries) {
    return (
      <AsyncMainSection 
        galleries={galleries}
        nightMode={this.state.nightMode}
        nightModeTextColor={this.state.colorSelection.nightModeColours.textColorNight}
        dayModeTextColor={this.state.colorSelection.dayModeColours.textColorDay}
        setImagesLoadedFromMainSection={this.setImagesLoadedFromMainSection}
      />
    );
  }

  /* Called by async components --------------------------------------------------------------------------------------------------------------------------------------------*/

  splashAction() {
    this.setState({
      splash: false
    })
  }

  setImagesLoadedFromMainSection() {
    this.setState({
      mainSectionImagesLoaded: true
    })
  }
}

export default App;

// @ts-check

const wSW: number = window.screen.width;

export const getSanityClient = (): any[] | Error => {
    const sanityClient: (arg: object) => {} = require("@sanity/client");
    const client: any = sanityClient({
        dataset: "production",
        projectId: "vci58u7u",
        useCdn: true,
    });

    return client
    .fetch(
        '*[_type in ["gallery", "headerNav", "about", "splashPage", "colorSelection", "errorPage"]]',
    )
    .then((res: any[]) => {
        return res;
    })
    .catch((err: Error) => {
        return err;
    });
};

export const getDataPlusUrls = (data: any[], sessionBrowser: string): object | undefined => {

    if (data) {
        let headerNavigation: object | undefined;
        let about: object | undefined;
        let splashPage: object | undefined;
        let errorPage: object | undefined;

        const galleries: any[] = [];
        const publicGalleries: any[] = [];
        const colorSelectionArr: any[] = [];

        const maxImageWidth: string = getImageWidth();
        const smallImageWidth: string = "?w=500";
        const logoWidth: string = getLogoWidth();

        for (const item of data) {

            switch (item._type) {
                case "gallery":
                    getDataPlusUrlsGallery(
                        item.images,
                        maxImageWidth,
                        item.coverImage.image,
                        smallImageWidth,
                        sessionBrowser,
                    );

                    if (!item.privateView) {
                        publicGalleries.push(item);
                    }

                    galleries.push(item);
                    break;
                case "headerNav":
                    item.logoPlusInfo.url = getImgRefUrl(item.logoPlusInfo.image, logoWidth, sessionBrowser);
                    getSocialLinkImages(item.socialLinks, maxImageWidth, sessionBrowser);
                    headerNavigation = item;
                    break;
                case "about":
                    item.image.url = getImgRefUrl(item.image.image, "?w=400", sessionBrowser);
                    item.image.tinyUrl = getImgRefUrl(item.image.image, "?w=64", sessionBrowser);
                    about = item;
                    break;
                case "splashPage":
                    item.image.url = getImgRefUrl(item.image.image, ("?w=" + wSW), sessionBrowser);
                    item.image.tinyUrl = getImgRefUrl(item.image.image, "?w=64", sessionBrowser);

                    item.logo.url = getImgRefUrl(item.logo.image, logoWidth, sessionBrowser);
                    item.logo.tinyUrl = getImgRefUrl(item.logo.image, "?w=64", sessionBrowser);

                    splashPage = item;
                    break;
                case "colorSelection":
                    item.nightModeColours.bGColorNight = {
                        backgroundColor: item.nightModeColours.nightModeBackgroundColour.hex,
                    };

                    item.dayModeColours.bGColorDay = {
                        backgroundColor: item.dayModeColours.dayModeBackgroundColour.hex,
                    };

                    item.nightModeColours.textColorNight = {
                        color: item.nightModeColours.nightModeTextcolour.hex,
                    };

                    item.dayModeColours.textColorDay = {
                        color: item.dayModeColours.dayModeTextcolour.hex,
                    };

                    colorSelectionArr.push(item);
                    break;
                case "errorPage":
                    item.image.url = getImgRefUrl(item.image.image, maxImageWidth, sessionBrowser);
                    item.image.tinyUrl = getImgRefUrl(item.image.image, "?w=64", sessionBrowser);
                    errorPage = item;
                    break;
                default:
            }
        }

        sortColorSchemesByDateCreated(colorSelectionArr);

        sortGalleryByDate(galleries);
        sortGalleryByDate(publicGalleries);

        const dataFromSanityDict = {
            about,
            colorSelection: colorSelectionArr[0],
            data,
            errorPage,
            galleries,
            headerNavigation,
            publicGalleries,
            splashPage,
        };

        return dataFromSanityDict;
    }
};

const getImageWidth = (): string => {

    let maxImageWidth: string;

    (wSW * 0.7) > 1500 ? maxImageWidth = "?w=" + (wSW * 0.7) : maxImageWidth = "?w=1500";
    return maxImageWidth;
};

const getLogoWidth = (): string => {

    let logoWidthNum: number;

    if ( wSW >= 1329 ) {
        logoWidthNum =  wSW * 0.40;
    } else {
        logoWidthNum = 0;
    }

    if ( logoWidthNum === 0 ) {
        logoWidthNum = 751;
    }

    const logoWidth: string = "?w=" + logoWidthNum;

    return logoWidth;
};

const getDataPlusUrlsGallery = (
    galleryImages: any[],
    maxImageWidth: string,
    coverImage: any,
    smallImageWidth: string,
    sessionBrowser: string,
): void => {

    for (const item of galleryImages) {
        item.url = getImgRefUrl(item.image, maxImageWidth, sessionBrowser);
        item.tinyUrl = getImgRefUrl(item.image, "?w=64", sessionBrowser);
    }

    coverImage.url = getImgRefUrl(coverImage, maxImageWidth, sessionBrowser);
    coverImage.urlSmall = getImgRefUrl(coverImage, smallImageWidth, sessionBrowser);
    coverImage.urlTiny = getImgRefUrl(coverImage, "?w=64", sessionBrowser);
};

const getImgRefUrl = (image: any, dimensionParam: string, sessionBrowser: string): string => {
    let imgUrl: string;

    if (image.asset === undefined) {
        imgUrl = "";
    } else {
        const baseUrlBegin: string = "https://cdn.sanity.io/images/vci58u7u/production/";
        const imgRef: string = image.asset._ref;
        const splitImgRef: string[] = imgRef.split("-", 4);
        const imgRefNew: string = splitImgRef[1] + "-" + splitImgRef[2];
        const fileExt: string = splitImgRef[3];
        const baseUrlEnd: string = "." + fileExt;
        const formatParam: string = getImageFormat(fileExt, sessionBrowser);
        imgUrl = baseUrlBegin + imgRefNew + baseUrlEnd + dimensionParam + formatParam;
    }

    return imgUrl;
};

const getImageFormat = (fileExt: string, sessionBrowser: string): string => {

    let param: string;

    if (fileExt === "svg") {
        param = "";
    } else {
        sessionBrowser === "chrome" || sessionBrowser === "opera" ? param = "&fm=webp" : param = "";
    }

    return param;
};

const getSocialLinkImages = (socialLinks: any[], maxImageWidth: string, sessionBrowser: string): void => {
    for (const link of socialLinks) {
        link.logo = getImgRefUrl(link.logo, maxImageWidth, sessionBrowser);
    }
};

const sortColorSchemesByDateCreated = (colorSchemes: any[]): void => {
    colorSchemes.sort((a, b) => {
        return (a._createdAt < b._createdAt) ? -1 : ((a._createdAt > b._createdAt) ? 1 : 0);
    });

    colorSchemes.reverse();
};

const sortGalleryByDate = (galleries: any[]): void => {
    galleries.sort((a, b) => {
        return (a.dateCompleted < b.dateCompleted) ? -1 : ((a.dateCompleted > b.dateCompleted) ? 1 : 0);
    });

    galleries.reverse();
};

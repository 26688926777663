import React from 'react';

let invisibleStyle = {
  opacity: "0",
  cursor: "default"
}

export const Loading = () => (
    <div>
    </div>
);

export const SplashMainMenu = () => (
    <div className="mainMenuContainer" id="splashMainMenuContainer" style={invisibleStyle}>
        <button className="navItemButton" id="homeNavButton" style={invisibleStyle}>Home</button>
        <button className="navItemButton" id="aboutNavButton" style={invisibleStyle}>About</button>
        <button className="navItemButton"  id="contactNavButton" style={invisibleStyle}>Contact</button>
    </div>
);
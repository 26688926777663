import Loadable from "react-loadable";
import { Loading } from './pureComponents';

export const AsyncHeader = Loadable({
    loader: () => import("./Header"),
    loading: Loading
});

export const AsyncMainSection = Loadable({
    loader: () => import("./MainSection"),
    loading: Loading
});

export const AsyncLightHeader = Loadable({
    loader: () => import("./LightHeader"),
    loading: Loading
});

export const AsyncNoMatch = Loadable({
    loader: () => import("./NoMatch"),
    loading: Loading
});

export const AsyncGallery = Loadable({
    loader: () => import("./Gallery"),
    loading: Loading
});

export const AsyncAbout = Loadable({
    loader: () => import("./About"),
    loading: Loading
});

export const AsyncContact = Loadable({
    loader: () => import("./Contact"),
    loading: Loading
});

export const AsyncSplash = Loadable({
    loader: () => import("./Splash"),
    loading: Loading
});
// @ts-check

class Gallery {
    public coverImage: object;
    public dateCompleted: string;
    public heading: string;
    public images: object[];
    public key: string;
    public privateView: boolean;
    public createdAt: string;
    public id: string;
    public rev: string;
    public type: string;
    public updatedAt: string;

    constructor(data?: any) {
        this.coverImage = data.coverImage || {};
        this.dateCompleted = data.dateCompleted || "";
        this.heading = data.heading || "";
        this.images = data.images || [];
        this.key = data.key || "";
        this.privateView = data.privateView || true;
        this.createdAt = data.createdAt || "";
        this.id = data.id || "";
        this.rev = data.rev || "";
        this.type = data.type || "";
        this.updatedAt = data.updatedAt || "";
    }
}

export const getGalleriesObject = (galleries: any[]): object => {

    const galleriesObject: any = {};

    for (const gallery of galleries) {
        const newGallery: Gallery = new Gallery(
            {
                coverImage: gallery.coverImage,
                createdAt: gallery._createdAt,
                dateCompleted: gallery.dateCompleted,
                heading: gallery.heading,
                id: gallery._id,
                images: gallery.images,
                key: gallery.key,
                privateView: gallery.privateView,
                rev: gallery._rev,
                type: gallery._type,
                updatedAt: gallery._updatedAt,
            },
        );

        galleriesObject[gallery.key] = newGallery;
    }
    return galleriesObject;
};
